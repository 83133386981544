import { LicenseRequestStatuses } from './enums/license';

enum SortDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}

type SortField = {
  field: 'updatedAt',
  direction: SortDirection.DESC,
} | {
  field: 'createdAt',
  direction: SortDirection.DESC,
};

const updatedAtDesc = {
  field: 'updatedAt',
  direction: SortDirection.DESC,
} satisfies SortField;

const createdAtDesc = {
  field: 'createdAt',
  direction: SortDirection.DESC,
} satisfies SortField;

export const sorting = {
  licenses: updatedAtDesc,
  documents: updatedAtDesc,
  peerReferences: createdAtDesc,
  credentials: updatedAtDesc,
  addresses: createdAtDesc,
  educations: createdAtDesc,
  practiceEmployers: createdAtDesc,
  facilityAffiliations: createdAtDesc,
  workHistories: createdAtDesc,
  boardCertifications: createdAtDesc,
  exams: createdAtDesc,
  providers: createdAtDesc,
  providerGroups: createdAtDesc,
  changelog: createdAtDesc,
  providerBirthInfo: createdAtDesc,
  providerDemographicInfo: createdAtDesc,
  providerMoreNames: createdAtDesc,
  malpracticeInsurance: createdAtDesc,
  importLog: createdAtDesc,
  healthcarePayors: createdAtDesc,
  cmes: createdAtDesc,
  licensePrimarySourceFiles: createdAtDesc,
  liecenseRequests: createdAtDesc,
  liecenseRequestStatuses: createdAtDesc,
  providerShares: createdAtDesc,
  providerShareDocs: createdAtDesc,
} satisfies Record<string, SortField>;

export const sortingLicenseRequestStatuses = {
  [LicenseRequestStatuses.Review]: 1,
  [LicenseRequestStatuses.Payment]: 2,
  [LicenseRequestStatuses.BoardProcessing]: 3,
  [LicenseRequestStatuses.Completed]: 4,
};
